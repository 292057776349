define("discourse/plugins/discourse-events/discourse/routes/preferences-webcal-keys", ["exports", "discourse/routes/restricted-user"], function (_exports, _restrictedUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _restrictedUser.default.extend({
    showFooter: true
  });
});