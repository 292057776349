define("discourse/plugins/discourse-events/discourse/controllers/admin-plugins-events-event", ["exports", "@ember/array", "@ember/controller", "@ember/object/computed", "@ember/service", "discourse-common/utils/decorators", "discourse/plugins/discourse-events/discourse/components/modal/events-confirm-event-deletion", "discourse/plugins/discourse-events/discourse/mixins/message"], function (_exports, _array, _controller, _computed, _service, _decorators, _eventsConfirmEventDeletion, _message) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_message.default, dt7948.p({
    hasEvents: (0, _computed.notEmpty)("events"),
    selectedEvents: (0, _array.A)(),
    modal: (0, _service.inject)(),
    selectAll: false,
    order: null,
    asc: null,
    viewName: "event",
    deleteDisabled(selectedEvents, hasEvents) {
      return !hasEvents || !selectedEvents.length;
    },
    selectDisabled(hasEvents) {
      return !hasEvents;
    },
    actions: {
      modifySelection(events, checked) {
        if (checked) {
          this.get("selectedEvents").pushObjects(events);
        } else {
          this.get("selectedEvents").removeObjects(events);
        }
      },
      openDelete() {
        var _this = this;
        this.modal.show(_eventsConfirmEventDeletion.default, {
          model: {
            events: this.selectedEvents,
            onDestroyEvents: function () {
              let destroyedEvents = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
              let destroyedTopicsEvents = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
              _this.set("selectedEvents", (0, _array.A)());
              if (destroyedEvents) {
                _this.get("events").removeObjects(destroyedEvents);
              }
              if (destroyedTopicsEvents) {
                const destroyedTopicsEventIds = destroyedTopicsEvents.map(e => e.id);
                _this.get("events").forEach(event => {
                  if (destroyedTopicsEventIds.includes(event.id)) {
                    event.set("topics", null);
                  }
                });
              }
            }
          }
        });
      }
    }
  }, [["method", "deleteDisabled", [(0, _decorators.default)("selectedEvents.[]", "hasEvents")]], ["method", "selectDisabled", [(0, _decorators.default)("hasEvents")]]]));
});