define("discourse/plugins/discourse-events/discourse/mixins/message", ["exports", "@ember/object", "@ember/object/mixin"], function (_exports, _object, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const defaultMessage = _object.default.create({
    key: "info"
  });
  var _default = _exports.default = _mixin.default.create({
    message: defaultMessage,
    setMessage(key, type, opts) {
      this.get("message").setProperties({
        key,
        type,
        opts
      });
    },
    actions: {
      setMessage(key) {
        let type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "info";
        let opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
        this.setMessage(key, type, opts);
      }
    }
  });
});