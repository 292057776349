define("discourse/plugins/discourse-events/discourse/models/connection", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/models/site", "discourse-common/utils/decorators"], function (_exports, _object, _ajax, _ajaxError, _site, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Connection = _object.default.extend(dt7948.p({
    category(categoryId) {
      const categories = _site.default.current().categoriesList;
      return categories.find(c => c.id === categoryId);
    }
  }, [["method", "category", [(0, _decorators.default)("category_id")]]]));
  Connection.reopenClass({
    all() {
      return (0, _ajax.ajax)("/admin/plugins/events/connection").catch(_ajaxError.popupAjaxError);
    },
    update(connection) {
      return (0, _ajax.ajax)(`/admin/plugins/events/connection/${connection.id}`, {
        type: "PUT",
        contentType: "application/json",
        data: JSON.stringify({
          connection
        })
      }).catch(_ajaxError.popupAjaxError);
    },
    sync(connection) {
      return (0, _ajax.ajax)(`/admin/plugins/events/connection/${connection.id}`, {
        type: "POST"
      }).catch(_ajaxError.popupAjaxError);
    },
    destroy(connection) {
      return (0, _ajax.ajax)(`/admin/plugins/events/connection/${connection.id}`, {
        type: "DELETE"
      }).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = Connection;
});