define("discourse/plugins/discourse-events/discourse/connectors/edit-topic/topic-controls-event", ["exports", "@ember/application", "discourse/models/category"], function (_exports, _application, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(_, ctx) {
      return ctx.siteSettings.events_enabled;
    },
    setupComponent(_, component) {
      const buffered = this.get("buffered");
      const user = component.currentUser;
      const showEventControls = category => {
        return category && category.events_enabled && (user.staff || user.trust_level >= category.events_min_trust_to_create);
      };
      component.set("showEventControls", showEventControls(buffered.get("category")));
      buffered.addObserver("category_id", () => {
        if (this._state === "destroying") {
          return;
        }
        let category = _category.default.findById(this.get("buffered.category_id"));
        component.set("showEventControls", showEventControls(category));
      });
    },
    actions: {
      updateEvent(event) {
        const controller = (0, _application.getOwner)(this).lookup("controller:topic");
        controller.set("buffered.event", event);
      }
    }
  };
});