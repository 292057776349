define("discourse/plugins/discourse-events/discourse/helpers/event-label", ["exports", "@ember/template", "discourse-common/lib/helpers", "discourse/plugins/discourse-events/discourse/lib/date-utilities"], function (_exports, _template, _helpers, _dateUtilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = eventLabelHelper;
  (0, _helpers.registerRawHelper)("event-label", eventLabelHelper);
  function eventLabelHelper(event, args) {
    let siteSettings = (0, _helpers.helperContext)().siteSettings;
    return (0, _template.htmlSafe)((0, _dateUtilities.eventLabel)(event, Object.assign({}, args, {
      siteSettings
    })));
  }
});