define("discourse/plugins/discourse-events/discourse/routes/admin-plugins-events-event", ["exports", "discourse/routes/discourse", "discourse/plugins/discourse-events/discourse/models/event"], function (_exports, _discourse, _event) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    queryParams: {
      order: {
        refreshModel: true
      },
      asc: {
        refreshModel: true
      }
    },
    model(params) {
      let page = params.page || 0;
      let order = params.order || "start_time";
      let asc = params.asc || false;
      return _event.default.list({
        page,
        order,
        asc
      });
    },
    setupController(controller, model) {
      controller.setProperties({
        page: model.page,
        events: _event.default.eventsArray(model.events)
      });
    }
  });
});