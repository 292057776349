define("discourse/plugins/discourse-events/discourse/lib/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contentsMap = contentsMap;
  _exports.convertName = convertName;
  function convertName(string) {
    return string.replace(/[_\-]+/g, " ").toLowerCase();
  }
  function contentsMap(items) {
    return items.map(item => {
      return {
        id: item,
        name: convertName(item)
      };
    });
  }
});