define("discourse/plugins/discourse-events/discourse/components/add-to-calendar", ["exports", "@glimmer/component", "@ember/helper", "@ember/service", "discourse/components/d-button", "discourse/components/dropdown-menu", "discourse-common/helpers/i18n", "float-kit/components/d-menu", "discourse/plugins/discourse-events/discourse/lib/date-utilities", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _service, _dButton, _dropdownMenu, _i18n, _dMenu, _dateUtilities, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AddToCalendar extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get calendarUris() {
      const topic1 = this.args.topic;
      let params1 = {
        event: topic1.event,
        title: topic1.title,
        url: window.location.hostname + topic1.get("url")
      };
      if (topic1.location && topic1.location.geo_location) {
        params1.location = topic1.location.geo_location.address;
      }
      return [{
        uri: (0, _dateUtilities.googleUri)(params1),
        label: "google"
      }, {
        uri: (0, _dateUtilities.icsUri)(params1),
        label: "ics"
      }];
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DMenu
          @identifier="add-to-calendar"
          @icon="far-calendar-plus"
          @label={{i18n "add_to_calendar.label"}}
          @autofocus={{true}}
        >
          <:trigger>
            {{yield}}
          </:trigger>
    
          <:content>
            <DropdownMenu as |dropdown|>
              <dropdown.item class="add-to-calendar-item">
                {{#each this.calendarUris as |c|}}
                  <DButton
                    @label={{concat "add_to_calendar." c.label}}
                    @href={{c.uri}}
                    rel="noopener noreferrer"
                    target="_blank"
                  />
                {{/each}}
              </dropdown.item>
            </DropdownMenu>
          </:content>
        </DMenu>
      
    */
    {
      "id": "Ef2deURx",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@identifier\",\"@icon\",\"@label\",\"@autofocus\"],[\"add-to-calendar\",\"far-calendar-plus\",[28,[32,1],[\"add_to_calendar.label\"],null],true]],[[\"trigger\",\"content\"],[[[[1,\"\\n        \"],[18,3,null],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,2],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[30,1,[\"item\"]],[[24,0,\"add-to-calendar-item\"]],null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"calendarUris\"]]],null]],null],null,[[[1,\"              \"],[8,[32,3],[[24,\"rel\",\"noopener noreferrer\"],[24,\"target\",\"_blank\"]],[[\"@label\",\"@href\"],[[28,[32,4],[\"add_to_calendar.\",[30,2,[\"label\"]]],null],[30,2,[\"uri\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"          \"]],[]]]]],[1,\"\\n        \"]],[1]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"dropdown\",\"c\",\"&default\"],false,[\"yield\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-events/discourse/components/add-to-calendar.js",
      "scope": () => [_dMenu.default, _i18n.default, _dropdownMenu.default, _dButton.default, _helper.concat],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AddToCalendar;
});