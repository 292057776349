define("discourse/plugins/discourse-events/discourse/components/events-calendar-subscription", ["exports", "discourse/models/category", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "I18n", "select-kit/components/dropdown-select-box"], function (_exports, _category, _getUrl, _decorators, _I18n, _dropdownSelectBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _dropdownSelectBox.default.extend(dt7948.p({
    classNames: ["events-calendar-subscription"],
    modifyComponentForRow() {
      return "events-calendar-subscription-row";
    },
    getDomain() {
      return location.hostname + (location.port ? ":" + location.port : "");
    },
    content() {
      const path = this.category ? `/c/${_category.default.slugFor(this.category)}/l` : "";
      const url = this.getDomain() + (0, _getUrl.default)(path);
      const timeZone = moment.tz.guess();
      return [{
        id: `webcal://${url}/calendar.ics?time_zone=${timeZone}`,
        name: _I18n.default.t("events_calendar.ical")
      }, {
        id: `${url}/calendar.rss?time_zone=${timeZone}`,
        name: _I18n.default.t("events_calendar.rss")
      }];
    },
    actions: {
      onSelect() {}
    }
  }, [["method", "content", [(0, _decorators.default)()]]]));
});