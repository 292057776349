define("discourse/plugins/discourse-events/discourse/templates/connectors/composer-fields/composer-controls-event", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.showEventControls}}
    <AddEventControls
      @event={{this.model.event}}
      @category={{this.model.category}}
      @noText={{this.site.mobileView}}
      @updateEvent={{this.updateEvent}}
    />
    <PopupInputTip @validation={{eventValidation}} />
  {{/if}}
  */
  {
    "id": "GoDvCWBn",
    "block": "[[[41,[30,0,[\"model\",\"showEventControls\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@event\",\"@category\",\"@noText\",\"@updateEvent\"],[[30,0,[\"model\",\"event\"]],[30,0,[\"model\",\"category\"]],[30,0,[\"site\",\"mobileView\"]],[30,0,[\"updateEvent\"]]]],null],[1,\"\\n  \"],[8,[39,2],null,[[\"@validation\"],[[30,0,[\"eventValidation\"]]]],null],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `eventValidation` property path was used in the `discourse/plugins/discourse-events/discourse/templates/connectors/composer-fields/composer-controls-event.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.eventValidation}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"add-event-controls\",\"popup-input-tip\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/templates/connectors/composer-fields/composer-controls-event.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});