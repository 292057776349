define("discourse/plugins/discourse-events/discourse/controllers/admin-plugins-events-provider", ["exports", "@ember/controller", "@ember/object/computed", "@ember/service", "I18n", "discourse/plugins/discourse-events/discourse/mixins/message", "discourse/plugins/discourse-events/discourse/models/provider"], function (_exports, _controller, _computed, _service, _I18n, _message, _provider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_message.default, {
    hasProviders: (0, _computed.notEmpty)("providers"),
    viewName: "provider",
    dialog: (0, _service.service)(),
    actions: {
      addProvider() {
        this.get("providers").pushObject(_provider.default.create({
          id: "new"
        }));
      },
      removeProvider(provider) {
        if (provider.id === "new") {
          this.get("providers").removeObject(provider);
        } else {
          this.dialog.confirm({
            message: _I18n.default.t("admin.events.provider.remove.confirm", {
              provider_name: provider.name
            }),
            confirmButtonLabel: "admin.events.provider.remove.label",
            cancelButtonLabel: "cancel",
            didConfirm: () => {
              _provider.default.destroy(provider).then(() => {
                this.get("providers").removeObject(provider);
              });
            }
          });
        }
      }
    }
  });
});