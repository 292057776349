define("discourse/plugins/discourse-events/discourse/routes/admin-plugins-events", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    afterModel(model, transition) {
      if (transition.to.name === "adminPlugins.events.index") {
        this.router.transitionTo("adminPlugins.events.event");
      }
    },
    actions: {
      showSettings() {
        const controller = this.controllerFor("adminSiteSettings");
        this.router.transitionTo("adminSiteSettingsCategory", "plugins").then(() => {
          controller.set("filter", "plugin:discourse-events");
          controller.set("_skipBounce", true);
          controller.filterContentNow("plugins");
        });
      }
    }
  });
});