define("discourse/plugins/discourse-events/discourse/discourse-events-admin-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: "admin.adminPlugins",
    path: "/plugins",
    map() {
      this.route("events", function () {
        this.route("provider");
        this.route("source");
        this.route("connection");
        this.route("event");
        this.route("log");
      });
    }
  };
});