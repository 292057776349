define("discourse/plugins/discourse-events/discourse/connectors/topic-title/event-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(_, ctx) {
      return ctx.siteSettings.events_enabled;
    },
    setupComponent(attrs, component) {
      const topic = attrs.model;
      let showRsvp = component.siteSettings.events_rsvp && topic.get("event.rsvp");
      component.set("showRsvp", showRsvp);
      topic.addObserver("event.rsvp", () => {
        if (this._state === "destroying") {
          return;
        }
        component.set("showRsvp", component.siteSettings.events_rsvp && topic.get("event.rsvp"));
      });
    }
  };
});