define("discourse/plugins/discourse-events/discourse/templates/connectors/edit-topic/topic-controls-event", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showEventControls}}
    <AddEventControls
      @event={{this.buffered.event}}
      @category={{this.buffered.category}}
      @noText={{this.site.mobileView}}
      @updateEvent={{this.updateEvent}}
    />
  {{/if}}
  */
  {
    "id": "HGxKxlfw",
    "block": "[[[41,[30,0,[\"showEventControls\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@event\",\"@category\",\"@noText\",\"@updateEvent\"],[[30,0,[\"buffered\",\"event\"]],[30,0,[\"buffered\",\"category\"]],[30,0,[\"site\",\"mobileView\"]],[30,0,[\"updateEvent\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"add-event-controls\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/templates/connectors/edit-topic/topic-controls-event.hbs",
    "isStrictMode": false
  });
});