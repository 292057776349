define("discourse/plugins/discourse-events/discourse/routes/discovery-calendar", ["exports", "discourse/plugins/discourse-events/discourse/routes/build-calendar-route"], function (_exports, _buildCalendarRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscoveryCategory extends (0, _buildCalendarRoute.default)({
    type: "topic"
  }) {}
  _exports.default = DiscoveryCategory;
});