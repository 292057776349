define("discourse/plugins/discourse-events/discourse/components/modal/events-source-options", ["exports", "@ember/component", "@ember/object", "discourse-common/utils/decorators", "I18n", "@ember/template-factory"], function (_exports, _component, _object, _decorators, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    class="events-source-options-modal"
    @closeModal={{@closeModal}}
    @title={{this.title}}
  >
    <:body>
      {{#each this.sourceOptions as |option|}}
        <div class="control-group">
          <label class="control-label">
            {{i18n (concat "admin.events.source.source_options." option.name)}}
          </label>
          <Input
            class="source-option"
            @type={{option.type}}
            name={{option.name}}
            @value={{option.value}}
            placeholder={{option.name}}
            {{on "change" (action "updateSourceOptions" option.name)}}
          />
        </div>
      {{/each}}
    </:body>
    <:footer>
      <DButton
        @class="btn-primary"
        @action={{@closeModal}}
        @label="admin.events.source.source_options.done.label"
        @title="admin.events.source.source_options.done.title"
      />
    </:footer>
  </DModal>
  */
  {
    "id": "4V1Shgfu",
    "block": "[[[8,[39,0],[[24,0,\"events-source-options-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[30,0,[\"title\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"sourceOptions\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"\\n          \"],[1,[28,[35,3],[[28,[37,4],[\"admin.events.source.source_options.\",[30,2,[\"name\"]]],null]],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[39,5],[[24,0,\"source-option\"],[16,3,[30,2,[\"name\"]]],[16,\"placeholder\",[30,2,[\"name\"]]],[4,[38,6],[\"change\",[28,[37,7],[[30,0],\"updateSourceOptions\",[30,2,[\"name\"]]],null]],null]],[[\"@type\",\"@value\"],[[30,2,[\"type\"]],[30,2,[\"value\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"]],[]],[[[1,\"\\n    \"],[8,[39,8],null,[[\"@class\",\"@action\",\"@label\",\"@title\"],[\"btn-primary\",[30,1],\"admin.events.source.source_options.done.label\",\"admin.events.source.source_options.done.title\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"option\"],false,[\"d-modal\",\"each\",\"-track-array\",\"i18n\",\"concat\",\"input\",\"on\",\"action\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/components/modal/events-source-options.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    title(providerType) {
      const providerLabel = _I18n.default.t(`admin.events.provider.provider_type.${providerType}`);
      const optsLabel = _I18n.default.t("admin.events.source.source_options.label");
      return `${providerLabel} ${optsLabel}`;
    },
    sourceOptions(source_options) {
      return this.model.sourceOptionFields.map(opt => {
        return {
          name: opt.name,
          value: source_options[opt.name],
          type: opt.type
        };
      });
    },
    updateSourceOptions(name, event) {
      this.model.source.source_options.set(name, event.target.value);
    }
  }, [["method", "title", [(0, _decorators.default)("model.providerType")]], ["method", "sourceOptions", [(0, _decorators.default)("model.source.source_options.@each")]], ["method", "updateSourceOptions", [_object.action]]])));
});