define("discourse/plugins/discourse-events/discourse/models/log", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _object, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Log = _object.default.extend();
  Log.reopenClass({
    list() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return (0, _ajax.ajax)("/admin/plugins/events/log", {
        type: "GET",
        data
      }).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = Log;
});